<!-- eslint-disable global-require -->
<!-- eslint-disable vue/html-self-closing -->
<template>
  <bcf-aside @close="close">
    <bcfAsideHeader :close="close">
      {{ $t('account.settings.api.title') }}
    </bcfAsideHeader>
    <bcfAsideContent>
      <loading-overlay :loading="inProgress">
        <Message
          v-if="warning"
          type="warning"
          :icon="true"
        >
          <strong>Attention!</strong> Please generate customer id before choosing a connection.
        </Message>
        <form>
          <SectionTitle>{{ $t('account.settings.api.subTitle1') }}</SectionTitle>
          <p>{{ $t('account.settings.api.message') }}</p>
          <div v-if="!hasCustomerKey">
            <p>{{ $t('account.settings.api.description') }}</p>
            <button
              style="martin-top: 2px;"
              class="button button--primary"
              @click.prevent="generate()"
            >
              {{ $t('account.settings.api.generate') }}
            </button>
          </div>
          <div
            v-else
            class="configuration__code"
          >
            <input
              ref="key"
              v-model="customer_uuid_key"
              readonly
              class="form-control"
              type="text"
            >
            <button
              id="copy-code"
              name="copy-code"
              @click.prevent="copyCode()"
            >
              <tippy
                trigger="manual"
                :visible="tippyShow[`copy-code`]"
                content="Code copied"
                append-to="parent"
              />
            </button>
          </div>
          <div />
        </form>
        <SectionTitle>{{ $t('account.settings.api.subTitle2') }}</SectionTitle>
        <!-- SINGLE SELECTION -->
        <vue-select-image
          :data-images="dataConnection"
          w="92"
          :selected-images="loadSelectedImage"
          @onselectimage="onSelectImage"
        />
        <div
          v-if="getApiConnType === 1"
          class="m-t-4"
        >
          <!-- Your code here -->
          <SectionTitle>{{ $t('account.settings.api.subTitle3') }}</SectionTitle>
          <p>
            {{ $t('account.settings.api.description3') }}
          </p>
          <div>
            <div class="copy-icon">
              <span id="copy-quote">
                <i
                  class="uil uil-copy-alt"
                  @click.prevent="copyExport('excel')"
                />
              </span>
            </div>
            <p
              id="excel-export"
              class="copy-link"
            >
              {{ connectionsBaseUrl }}/api/v1/export/excel?language={{ locale }}&key={{ connectionsBcfKey }}&cusid={{ customer_uuid_key }}
            </p>
          </div>
        </div>
        <div
          v-if="getApiConnType === 2"
          class="m-t-4"
        >
          <!-- Your code here -->
          <SectionTitle>{{ $t('account.settings.api.subTitle4') }}</SectionTitle>
          <p>
            {{ $t('account.settings.api.description4') }}
          </p>
          <div>
            <div class="copy-icon">
              <span id="copy-quote">
                <i
                  class="uil uil-copy-alt"
                  @click.prevent="copyExport('csv')"
                />
              </span>
            </div>
            <p
              id="csv-export"
              class="copy-link"
            >
              {{ connectionsBaseUrl }}/api/v1/export/csv?language={{ locale }}&key={{ connectionsBcfKey }}&cusid={{ customer_uuid_key }}
            </p>
          </div>
        </div>
        <div
          v-if="getApiConnType === 3"
          class="m-t-4"
        >
          <!-- Your code here -->
          <SectionTitle>{{ $t('account.settings.api.subTitle5') }}</SectionTitle>
          <p>
            {{ $t('account.settings.api.description5') }}
          </p>
          <div>
            <div class="copy-icon">
              <span id="copy-quote">
                <i
                  class="uil uil-copy-alt"
                  @click.prevent="copyExport('json')"
                />
              </span>
            </div>
            <p
              id="json-export"
              class="copy-link"
            >
              {{ connectionsBaseUrl }}/api/v1/export/json?language={{ locale }}&key={{ connectionsBcfKey }}&cusid={{ customer_uuid_key }}
            </p>
          </div>
        </div>
        <div v-if="getApiConnType === 4">
          <SectionTitle>{{ $t('account.settings.api.subTitle6') }}</SectionTitle>
          <p v-html="$t('account.settings.api.description6')"></p>
          <p
            class="copy-link"
            v-html="$t('account.settings.api.description6b')"
          ></p>
        </div>
        <div v-if="getApiConnType === 5">
          <SectionTitle>{{ $t('account.settings.api.subTitle8') }}</SectionTitle>
          <p>{{ $t('account.settings.api.description8') }}</p>
          <SectionTitle>{{ $t('account.settings.api.subTitle9') }}</SectionTitle>
          <p>
            {{ $t('account.settings.api.description9') }}
          </p>
          <p v-html="$t('account.settings.api.description9b')"></p>
          <div class="copy-icon">
            <span id="copy-quote">
              <i
                class="uil uil-copy-alt"
                @click.prevent="copyExport('configurator')"
              />
            </span>
          </div>
          <p
            id="configurator"
            class="copy-link"
          >
            {{
              embedConfiguratorScript1
            }}
          </p>
          <SectionTitle>{{ $t('account.settings.api.subTitle10') }}</SectionTitle>
          <p v-html="$t('account.settings.api.description10')">
          </p>
          <SectionTitle>{{ $t('account.settings.api.subTitle11') }}</SectionTitle>
          <p
            v-html="$t('account.settings.api.description11')"
          >
          </p>
          </p>
          <div class="copy-icon">
            <span id="copy-quote">
              <i
                class="uil uil-copy-alt"
                @click.prevent="copyExport('configurator2')"
              />
            </span>
          </div>
          <p
            id="configurator2"
            class="copy-link"
          >
            {{
              embedConfiguratorScript2
            }}
          </p>
        </div>
        <div v-if="getApiConnType === 6">
          <SectionTitle>{{ $t('account.settings.api.subTitle12') }}</SectionTitle>
          <p>
            {{ $t('account.settings.api.description12') }}
          </p>
          <SectionTitle>{{ $t('account.settings.api.subTitle13') }}</SectionTitle>
          <div class="image-container">
            <img
              src="../../../assets/img/brands/CS01.jpg"
              alt="Responsive Image"
              class="responsive-image"
            >
          </div>
          <br>
          <SectionTitle>{{ $t('account.settings.api.subTitle15') }}</SectionTitle>
          <div class="image-container">
            <img
              src="../../../assets/img/brands/CS02.jpg"
              alt="Responsive Image"
              class="responsive-image"
            >
          </div>
          <br>
          <SectionTitle>{{ $t('account.settings.api.subTitle18') }}</SectionTitle>
          <div class="image-container">
            <img
              src="../../../assets/img/brands/CS03.jpg"
              alt="Responsive Image"
              class="responsive-image"
            >
          </div>
          <br>
          <SectionTitle>{{ $t('account.settings.api.subTitle19') }}</SectionTitle>
          <p>{{ $t('account.settings.api.description19b') }}</p>
          <div class="image-container">
            <img
              src="../../../assets/img/brands/CS04.jpg"
              alt="Responsive Image"
              class="responsive-image"
            >
          </div>
          <br>
          <SectionTitle>{{ $t('account.settings.api.subTitle20') }}</SectionTitle>
          <p>{{ $t('account.settings.api.description20b') }}</p>
          <p
            class="copy-link"
            v-html="$t('account.settings.api.description15e')"
          >
          </p>
        </div>
        <div v-if="getApiConnType === 7">
          <SectionTitle>{{ $t('account.settings.api.subTitle16') }}</SectionTitle>
          <p>
            {{ $t('account.settings.api.description16') }}
          </p>
          <p class="copy-link">
            {{ $t('account.settings.api.subTitle17') }} <a
              target="_blank"
              href="https://helpcenter.channable.com"
            >Channable help docs</a>
          </p>
          </p>
        </div>
        <div
          v-if="getApiConnType === 8"
          class="m-t-4"
        >
          <!-- Your code here -->
          <SectionTitle>Intro - channable</SectionTitle>
          <p>
          </p>
          <div>
            <p v-html="$t('account.settings.api.description18')">
            </p>
          </div>
        </div>
        <div
          v-if="getApiConnType === 9"
          class="m-t-4"
        >
          <!-- Your code here -->
          <SectionTitle>Intro - Veloconnect</SectionTitle>
          <p>
          </p>
          <div>
            <p v-html="$t('account.settings.api.description19')">
            </p>
          </div>
          <SectionTitle>{{ $t('account.settings.api.subtitle19d') }}</SectionTitle>
          <p v-html="$t('account.settings.api.description19c')">
          </p>
          <p>URL:</p>
          <div>
            <div class="copy-icon">
              <span id="copy-quote">
                <i
                  class="uil uil-copy-alt"
                  @click.prevent="copyExport('veloconnect-link')"
                />
              </span>
            </div>
            <p
              id="url"
              class="copy-link"
            >
              https://connections.cyclingfactory.be/veloconnect/v1
            </p>
          </div>
          <p>BuyersID(userid):</p>
          <div v-if="!hasCustomerKey">
            <p>Please click the generate button first!!</p>
          </div>
          <div v-if="hasCustomerKey">
            <div class="copy-icon">
              <span id="copy-quote">
                <i
                  class="uil uil-copy-alt"
                  @click.prevent="copyExport('veloconnect-buyer')"
                />
              </span>
            </div>
            <p
              id="buyer"
              class="copy-link"
            >
              {{ customer_uuid_key }}
            </p>
          </div>
          <p>Password:</p>
          <div>
            <div class="copy-icon">
              <span id="copy-quote">
                <i
                  class="uil uil-copy-alt"
                  @click.prevent="copyExport('veloconnect-pw')"
                />
              </span>
            </div>
            <p
              id="pw"
              class="copy-link"
            >
              eda99171-6bdf-4401-baf1-189a8ddaa4a0
            </p>
          </div>
        </div>
      </loading-overlay>
    </bcfAsideContent>
    <bcfAsideFooter v-if="!isSucces">
      <button
        class="button button--outline"
        :disabled="inProgress"
        @click="close()"
      >
        {{ $t('account.settings.api.cancel') }}
      </button>
    </bcfAsideFooter>
  </bcf-aside>
</template>
<script>
import { mapState } from 'vuex';
import bcfAside from '@/components/bcfAside.vue';
import { generateKey } from '@/api/api';
import SectionTitle from '@/elements/SectionTitle.vue';
import bcfAsideHeader from '@/components/bcfAsideHeader.vue';
import bcfAsideFooter from '@/components/bcfAsideFooter.vue';
import bcfAsideContent from '@/components/bcfAsideContent.vue';
import loadingOverlay from '@/elements/LoadingOverlay.vue';
import VueSelectImage from 'vue-select-image';
import { tippy } from 'vue-tippy';
import Message from '@/components/Message.vue';

// add stylesheet
require('vue-select-image/dist/vue-select-image.css');

async function copyToClipboard(text) {
  await navigator.clipboard.writeText(text);
  const result = document.execCommand('copy');
  return result;
}

export default {
  components: {
    bcfAside,
    bcfAsideHeader,
    bcfAsideFooter,
    bcfAsideContent,
    SectionTitle,
    Message,
    loadingOverlay,
    tippy,
    VueSelectImage,
  },
  data() {
    return {
      isSucces: false,
      inProgress: false,
      error: false,
      customer_uuid_key: '',
      tippyShow: {},
      selectedConnection: null,
      selectedImage: [],
      dataConnection: [
        {
          id: 1,
          alt: 'BCF XLSX',
          // eslint-disable-next-line global-require
          src: require('../../../assets/img/brands/BCF-hori-pos-cmyk-XLSX.png'),
          disabled: false,
        },
        {
          id: 2,
          alt: 'BCF CSV',
          // eslint-disable-next-line global-require
          src: require('../../../assets/img/brands/BCF-hori-pos-cmyk-CSV.png'),
          disabled: false,
        },
        {
          id: 3,
          alt: 'BCF JSON',
          // eslint-disable-next-line global-require
          src: require('../../../assets/img/brands/BCF-hori-pos-cmyk-JSON.png'),
          disabled: false,
        },
        {
          id: 4,
          alt: 'BCF ADVANCED',
          // eslint-disable-next-line global-require
          src: require('../../../assets/img/brands/BCF-hori-pos-cmyk-ADV.png'),
          disabled: false,
        },
        {
          id: 5,
          alt: 'BCF EMBEDDED CONFIGURATOR',
          // eslint-disable-next-line global-require
          src: require('../../../assets/img/brands/BCF-hori-pos-cmyk-CONFIG.png'),
          disabled: false,
        },
        {
          id: 6,
          alt: 'CycleSoftware',
          // eslint-disable-next-line global-require
          src: require('../../../assets/img/brands/cyclesoftware-transp.png'),
          disabled: false,
        },
        {
          id: 7,
          alt: 'Shopify',
          // eslint-disable-next-line global-require
          src: require('../../../assets/img/brands/shopify.png'),
          disabled: false,
        },
        {
          id: 8,
          alt: 'Channable',
          // eslint-disable-next-line global-require
          src: require('../../../assets/img/brands/channable2.png'),
          disabled: false,
        },
        {
          id: 9,
          alt: 'Veloconnect',
          // eslint-disable-next-line global-require
          src: require('../../../assets/img/brands/veloconnect.png'),
          disabled: false,
        },
      ],
      connectionsBaseUrl: process.env.VUE_APP_BCF_CONNECTIONS_BASE_URL,
      connectionsBcfKey: process.env.VUE_APP_BCF_CONNECTIONS_KEY,
      locale: 'en_BE',
      warning: false,
      embedConfiguratorScript1: '<script src="https://components.cyclingfactory.be/bcf-customizer/latest/bcf-customizer-incl.min.js"><\/script> <bcf-customizer-incl model-code="FALCONRS7E7"></bcf-customizer-incl>', // eslint-disable-line no-useless-escape,
      embedConfiguratorScript2: '<script src="https://components.cyclingfactory.be/bcf-customizer/latest/bcf-customizer-incl.min.js"><\/script><bcf-customizer-incl model-code="FALCONRS7E7" allow-checkout="true" max-delivery-days="700"></bcf-customizer-incl><script>const customizer = document.querySelector("bcf-customizer-incl");customizer.addEventListener("add", function (ev) {const selection = ev.detail[0];console.log(selection);});<\/script>', // eslint-disable-line no-useless-escape,
    };
  },
  computed: {
    loadSelectedImage() {
      return [this.selectedConnection];
    },
    getApiConnType() {
      return this.selectedConnection?.id || 0;
    },
    hasCustomerKey() {
      return this.customer.customeruuidkey !== null;
    },
    ...mapState({
      user: (state) => state.auth.user,
      customer: (state) => state.customer.selectedCustomer,
    }),
  },
  mounted() {
    this.locale = this.customer.attributes.locale;
    this.$store.dispatch('customer/refreshCustomer', this.customer.id);
    if (this.customer.customeruuidkey !== null) {
      this.customer_uuid_key = this.customer.customeruuidkey;
    }
  },
  methods: {
    onSelectImage(conn) {
      if (this.customer_uuid_key === '') {
        this.warning = true;
        return;
      }

      this.selectedConnection = conn;
      this.$store.dispatch('customer/setCustomerUuid', {
        customerUuid: this.customer_uuid_key,
        apiconn: conn,
      });
    },
    showTippy(tippyName, duration) {
      this.$nextTick(() => {
        const existing = this.tippyShow[tippyName];
        this.tippyShow = {
          ...this.tippyShow,
          [tippyName]: setTimeout(
            () => this.$nextTick(() => {
              this.tippyShow = {
                ...this.tippyShow,
                [tippyName]: false,
              };
            }),
            duration,
          ),
        };
        if (existing) {
          // if previous value existed, clear its timeout, so it doesnt hide the tippy before it new duration has elapsed
          clearTimeout(existing);
        }
      });
    },
    copyExport(type) {
      let copied = false;
      if (type === 'excel') {
        copied = copyToClipboard(document.querySelector('#excel-export').innerText);
      }
      if (type === 'csv') {
        copied = copyToClipboard(document.querySelector('#csv-export').innerText);
      }
      if (type === 'json') {
        copied = copyToClipboard(document.querySelector('#json-export').innerText);
      }
      if (type === 'configurator') {
        copied = copyToClipboard(document.querySelector('#configurator').innerText);
      }
      if (type === 'configurator2') {
        copied = copyToClipboard(document.querySelector('#configurator2').innerText);
      }
      if (type === 'veloconnect-pw') {
        copied = copyToClipboard(document.querySelector('#pw').innerText);
      }
      if (type === 'veloconnect-link') {
        copied = copyToClipboard(document.querySelector('#url').innerText);
      }
      if (type === 'veloconnect-buyer') {
        copied = copyToClipboard(document.querySelector('#buyer').innerText);
      }
      if (!copied) {
        throw new Error();
      } else {
        this.showTippy('copy-code', 3000);
        console.log('Copied to clipboard', copied);
      }
    },
    copyCode() {
      const copied = copyToClipboard(this.customer_uuid_key);
      if (!copied) {
        throw new Error();
      } else {
        this.showTippy('copy-code', 3000);
        console.log('Copied to clipboard', this.customer_uuid_key);
      }
    },
    async generate() {
      try {
        this.inProgress = true;
        // const cid = this.$store.state.customer.selectedCustomer.id;
        const result = await generateKey(this.customer.id);

        // Added just to see and check if the key generate is hanging is solved
        // I also added try catch
        await new Promise((resolve) => setTimeout(resolve, 2000));
        if (result) {
          this.$store.dispatch('customer/setCustomerUuid', {
            customerUuid: result.uuid,
            apiconn: result.conn,
          });
        }

        this.customer_uuid_key = result.uuid;
        this.warning = false;
        this.inProgress = false;
        this.selectedConnection = null;
      } catch (err) {
        // Error handling code
        console.error('Error generating key:', err);
      }
    },
    close() {
      this.selectedImage = [];
      this.selectedConnection = null;
      this.$router.push({ name: 'account' });
    },
  },
};
</script>
<style lang="scss">
    .copy-icon i {
      cursor: pointer;
      color:black;
    }
    .configuration__code {
        // border: 1px solid #DDD;
        display: flex;
        height: 56px;
        align-items: center;
        padding: 0 16px;
        justify-content: space-between;
        margin-top: 16px;
        border-radius: 4px;

        label {
            margin: 0;
            padding: 0;
            font-size: 12px;
            opacity: .5;
        }

        strong {
            width: 100%;
            text-align: center;
        }

        button {
            background-size: 12px;
            cursor: pointer;
            margin-left: 1px;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url('../../../assets/img/icons/copy.svg');
            border: 0;
            background-color: rgba(black, .1);
            height: 5rem;
            min-width: 5rem;
            border-radius: 3px;
        }
    }
.vue-select-image {
  &__thumbnail {
    img {
          max-width: 100px;
          height: 50px;
          margin: 2px;
          border: 1px solid #ddd;
    }
    &--selected {
      background: #bbb;
    }
    &--disabled {
      background: white;
    }
  }
}
.image-container {
            width: 100%;
            height: 20vh;
            display: flex;
            padding-left:15px;
            justify-content: left;
            align-items: center;
            overflow: hidden;
        }

        .responsive-image {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
        }

        @media (max-width: 768px) {
            .responsive-image {
                max-width: 90%;
                max-height: 90%;
            }
        }
</style>
